<template>
  <div class="login">
    <div class="thepic">
        <p>
          Have you noticed tracks becoming disabled in your Spotify playlists?
        </p>
        <p>
          <img src="@/assets/spotifyscreenshot.png" alt="Playlist Hospital Logo" width="500px" />
        </p>
    </div>
    <div class="thetext">
      <p>
        Record labels often change track availability, leaving your playlists
        with gaps that are hard to notice and fix. Spotify usually has other
        recordings of the same song, but you have to find and replace them by
        hand.
      </p>
      <p>
        At the <span class="green">Playlist Hospital</span> we help you find and
        restore broken tracks easily, so that you can continue enjoying your
        favourite music.
      </p>
    </div>
    <div class="thebutton">
      <p>
        <button @click="loginWithSpotify">Login with Spotify</button>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheLogin',
  methods: {
    loginWithSpotify() {
      const clientId = '8653394981dd498e8c37e42f7d35ef4e'; 
      const redirectUri = encodeURIComponent(this.$rootUri + "/callback");
      const scopes = encodeURIComponent('playlist-read-private playlist-modify-public playlist-modify-private');
      const responseType = 'token';
      const authUrl = `https://accounts.spotify.com/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes}&response_type=${responseType}&show_dialog=true`;
      window.location.href = authUrl;
    },
  },
};
</script>

<style>
.home {
  width: 100%;
  height: 100%;
  text-align: center;
}
.thepic {
  img {
    width: 500px;
  }
  text-align: center;
  margin: 0 0 40px 0;
  p {
    margin-bottom: 40px;
  }
}
.thetext {
  width: 660px;
  margin: 0 auto;
}
.thebutton {
  justify-content: center;
  align-items: center;
  display: flex;
}
</style>