import { createRouter, createWebHistory } from 'vue-router';
import TheCallback from '@/components/TheCallback'; 
import TheLogin from '@/components/TheLogin'; 
import ThePlaylists from '@/components/ThePlaylists';

const routes = [
    {
        path: "/",
        name: "login",
        component: TheLogin,
    },
    {
        path: "/callback",
        name: "callback",
        component: TheCallback,
    },
    {
        path: "/playlists",
        name: "playlists",
        component: ThePlaylists,
    },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;