export function sendEvent(eventName) {
  const data = {
    payload: {
      hostname: window.location.hostname,
      language: navigator.language,
      referrer: document.referrer,
      screen: `${window.screen.width}x${window.screen.height}`,
      title: document.title,
      url: window.location.pathname,
      website: '884de609-4458-4339-833c-f6080980c502',
      name: eventName,
    },
    type: 'event',
  };

  fetch('https://cloud.umami.is/api/send', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'User-Agent': navigator.userAgent,
    },
    body: JSON.stringify(data),
  })
  .then(response => response.json())
  .then(data => {
    console.log('Telemetry event sent successfully:', data);
  })
  .catch(error => {
    console.error('Error sending telemetry event:', error);
  });
}
