<template>
    <div class="splitcontent">
        <div class="playlists">
            <div class="playlistsHead">
                <div>
                    <h2>Your Playlists</h2>
                </div>
                <div class="marketSelector">
                    <span>
                        Where do you listen from?
                    </span>
                    <select v-model="selectedMarket" @change="onMarketChange">
                        <option value="AD">Andorra</option>
                        <option value="AR">Argentina</option>
                        <option value="AU">Australia</option>
                        <option value="AT">Austria</option>
                        <option value="BE">Belgium</option>
                        <option value="BO">Bolivia</option>
                        <option value="BR">Brazil</option>
                        <option value="BG">Bulgaria</option>
                        <option value="CA">Canada</option>
                        <option value="CL">Chile</option>
                        <option value="CO">Colombia</option>
                        <option value="CR">Costa Rica</option>
                        <option value="HR">Croatia</option>
                        <option value="CY">Cyprus</option>
                        <option value="CZ">Czech Republic</option>
                        <option value="DK">Denmark</option>
                        <option value="DO">Dominican Republic</option>
                        <option value="EC">Ecuador</option>
                        <option value="SV">El Salvador</option>
                        <option value="EE">Estonia</option>
                        <option value="FI">Finland</option>
                        <option value="FR">France</option>
                        <option value="DE">Germany</option>
                        <option value="GR">Greece</option>
                        <option value="GT">Guatemala</option>
                        <option value="HN">Honduras</option>
                        <option value="HK">Hong Kong</option>
                        <option value="HU">Hungary</option>
                        <option value="IS">Iceland</option>
                        <option value="IN">India</option>
                        <option value="ID">Indonesia</option>
                        <option value="IE">Ireland</option>
                        <option value="IL">Israel</option>
                        <option value="IT">Italy</option>
                        <option value="JP">Japan</option>
                        <option value="JO">Jordan</option>
                        <option value="KZ">Kazakhstan</option>
                        <option value="KE">Kenya</option>
                        <option value="KR">South Korea</option>
                        <option value="LV">Latvia</option>
                        <option value="LB">Lebanon</option>
                        <option value="LI">Liechtenstein</option>
                        <option value="LT">Lithuania</option>
                        <option value="LU">Luxembourg</option>
                        <option value="MY">Malaysia</option>
                        <option value="MT">Malta</option>
                        <option value="MX">Mexico</option>
                        <option value="MC">Monaco</option>
                        <option value="ME">Montenegro</option>
                        <option value="MA">Morocco</option>
                        <option value="NL">Netherlands</option>
                        <option value="NZ">New Zealand</option>
                        <option value="NI">Nicaragua</option>
                        <option value="NG">Nigeria</option>
                        <option value="NO">Norway</option>
                        <option value="OM">Oman</option>
                        <option value="PK">Pakistan</option>
                        <option value="PA">Panama</option>
                        <option value="PY">Paraguay</option>
                        <option value="PE">Peru</option>
                        <option value="PH">Philippines</option>
                        <option value="PL">Poland</option>
                        <option value="PT">Portugal</option>
                        <option value="QA">Qatar</option>
                        <option value="RO">Romania</option>
                        <option value="RU">Russia</option>
                        <option value="SA">Saudi Arabia</option>
                        <option value="RS">Serbia</option>
                        <option value="SG">Singapore</option>
                        <option value="SK">Slovakia</option>
                        <option value="SI">Slovenia</option>
                        <option value="ZA">South Africa</option>
                        <option value="ES">Spain</option>
                        <option value="LK">Sri Lanka</option>
                        <option value="SE">Sweden</option>
                        <option value="CH">Switzerland</option>
                        <option value="TW">Taiwan</option>
                        <option value="TZ">Tanzania</option>
                        <option value="TH">Thailand</option>
                        <option value="TN">Tunisia</option>
                        <option value="TR">Turkey</option>
                        <option value="UG">Uganda</option>
                        <option value="UA">Ukraine</option>
                        <option value="AE">United Arab Emirates</option>
                        <option value="GB">United Kingdom</option>
                        <option value="US">United States</option>
                        <option value="UY">Uruguay</option>
                        <option value="UZ">Uzbekistan</option>
                        <option value="VE">Venezuela</option>
                        <option value="VN">Vietnam</option>
                        <option value="ZM">Zambia</option>
                        <option value="ZW">Zimbabwe</option>
                    </select>
                </div>
                <div>
                    <p v-if="selectedMarket">Scan a playlist to find and fix unplayable tracks</p>
                    <p v-else>To analyze your playlists select your country first!
                        <span  title="This is required in order to find tracks that are licensed to play in your region" class="hint">why?</span>
                    </p>
                </div>
            </div>
            <div class="playlistsContent">
                <ul v-if="Object.keys(playlists).length">
                    <li v-for="(playlist, playlistId) in playlists" :key="playlistId">
                        <div class="rowish">
                            <div>
                                <a :href="playlist.external_urls.spotify" :title="`'${playlist.name}' in Spotify`" target="_blank">{{ truncateString(playlist.name, 30) }}</a>
                                <span style="margin-left:5px">({{ playlist.tracks.total }} tracks{{ playlist.unplayableCount > 0 ? ", " + playlist.unplayableCount + " broken": "" }})</span>
                            </div>
                            <button v-if="selectedMarket" @click="fetchPlaylistTracks(playlist.id)">Scan</button>
                        </div>
                    </li>
                </ul>
                <p v-else>Loading playlists...</p>
            </div>
        </div>
        <div class="details">
            <div class="detailsHead" v-if="currentPlaylistId != null">
                <div class="cover">
                    <a :href="`${playlists[currentPlaylistId].external_urls.spotify}`" target="_blank" :title="`'${playlists[currentPlaylistId].name}' in Spotify`">
                        <img :src="playlists[currentPlaylistId].images[0].url" class="cover" :alt="`${playlists[currentPlaylistId].name} in Spotify`" />
                    </a>
                </div>
                <div class="info">
                    <a :href="`${playlists[currentPlaylistId].external_urls.spotify}`" target="_blank" :title="`'${playlists[currentPlaylistId].name}' in Spotify`">
                        <h2>{{ playlists[currentPlaylistId].name }}</h2>
                    </a>
                    <div>
                        <!-- show total number of tracks in curentPlaylistTracks -->
                        {{ currentPlaylistTracks.length }} tracks {{ playlists[currentPlaylistId].unplayableCount > 0 ? ", " + playlists[currentPlaylistId].unplayableCount + " broken": "" }}
                        <a :href="`${playlists[currentPlaylistId].external_urls.spotify}`" target="_blank" :title="`'${playlists[currentPlaylistId].name}' in Spotify`">
                            <p><img :src="require('@/assets/spotify_icon_green.png')" class="spotifylogo" alt="Listen on Spotify"/>Listen on Spotify</p>
                        </a>
                    </div>
                </div>
            </div>
            <ul v-if="currentPlaylistTracks != null">
                <li v-for="track in currentPlaylistTracks" :key="track.track.id" :class="{'non_playable': !track.track.is_playable, fixed: track.isFixed}">
                    <div class="track">
                        <div class="rowish">
                            <div>
                                <span v-if="!track.track.is_playable" title="This track is not available in your region" class="icon">❌</span>
                                <span v-else class="icon"><a :href="track.track.external_urls.spotify" target="_blank" :title="`Listen to '${track.track.name}' on Spotify`" :alt="`Listen to '${track.track.name}' on Spotify`">
                                    <img :src="require('@/assets/spotify_icon_green.png')" class="spotifylogo"/></a>
                                </span>
                                <img v-if="track.track.album.images.length > 0" :src="track.track.album.images[0].url" :title="`Listen to '${track.track.name}' on Spotify`" :alt="`Listen to ${track.track.name}' on Spotify`" class="coversmall"/>
                                <span v-else :title="`'${track.track.name}' is only available on your local hard drive`" class="coversmall">💾</span>
                                <div class="trackMeta">
                                    <template v-if="track.track.is_local">
                                            <span class="title">{{ track.track.name }}</span>
                                            <span class="artist" v-html="renderArtists(track.track.artists)"></span>
                                    </template>
                                    <template v-else>
                                            <span class="title"><a :href="track.track.external_urls.spotify" target="_blank" :title="`'${track.track.name}' in Spotify`" :class="{'broken_link': !track.track.is_playable}">{{ track.track.name }}</a></span>
                                            <span class="artist" v-html="renderArtists(track.track.artists)"></span>
                                    </template>
                                </div>
                            </div>
                            <template v-if="!track.track.is_playable && !track.isFixed">
                                <template v-if="track.isScanned">
                                    <button v-if="!track.alternatives" class="disabled" title="We could not find alternatives for this track">
                                        0 results
                                    </button>
                                    <button v-else-if="track.showAlternatives" @click="findAlternatives(track.track.id)" title="Hide alternatives for this track">
                                        Hide {{ track.alternatives.length }} alternatives
                                    </button>
                                    <button v-else @click="findAlternatives(track.track.id)" title="Show alternatives for this track">
                                        Show {{ track.alternatives.length }} alternatives
                                    </button>
                                </template>
                                <template v-else>
                                    <button @click="findAlternatives(track.track.id)" title="Find alternatives for this track">Find alternatives</button>
                                </template>
                            </template>
                        </div>
                        <ul v-if="track.alternatives && track.showAlternatives" class="alternatives">
                            <li v-for="alternative in track.alternatives" :key="alternative.id" :class="{'chosen': alternative.chosen}">
                                <div class="rowish">
                                    <img v-if="alternative.album.images.length > 0" :src="alternative.album.images[0].url" :title="`Listen to ${alternative.name} on Spotify`" :alt="`Listen to ${alternative.name} on Spotify`" class="coversmall"/>
                                    <div class="trackMeta">
                                        <span class="title"><a :href="alternative.external_urls.spotify" target="_blank">{{ alternative.name }}</a></span>
                                        <span class="artist" v-html="renderArtists(alternative.artists)"></span>
                                    </div>
                                    <button v-if="!track.isFixed" @click="applyFix(currentPlaylistId, track.track.id, alternative.id)">Apply</button>
                                    <button v-else class="repaired">Repaired!</button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ThePlaylists',
    data() {
        return {
            playlists: {},
            userId: null,
            selectedMarket: localStorage.getItem('market'),
            currentPlaylistTracks: [],
            currentPlaylistId: null,
        };
    },
    async mounted() {
        await this.fetchUserId();
        await this.fetchPlaylists();
        this.$sendEvent('playlists-page-viewed')
    },
    methods: {
        truncateString(str, num) {
            if (str.length <= num) {
                return str;
            }
            return str.slice(0, num) + '...';
        },
        onMarketChange() {
            localStorage.setItem('market', this.selectedMarket);
            console.log("Market changed to " + this.selectedMarket)
        },
        renderArtists(artists) {
            return artists.map((artist, index) => {
                const separator = index < artists.length - 1 ? ', ' : '';
                return `<a href="${artist.external_urls.spotify}" title="Listen to ${artist.name} on Spotify" alt="Listen to ${artist.name} on Spotify" target="_blank">${artist.name}</a>${separator}`;
            }).join('');
        },
        async fetchUserId() {
            // User ID needed to filter playlists by the owner
            const accessToken = localStorage.getItem('spotify_access_token');
            const response = await fetch('https://api.spotify.com/v1/me', {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            const data = await response.json();
            this.userId = data.id;
        },
        async fetchPlaylists() {
            this.$sendEvent('playlists-fetch')
            const accessToken = localStorage.getItem('spotify_access_token');
            let url = 'https://api.spotify.com/v1/me/playlists';
            const playlists = {};
            try {
                while (url) {
                    const response = await fetch(url, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });

                    if (response.status === 401) {
                        // TODO: renew token
                        console.log('Token expired');
                        this.$router.push({ name: 'login' });
                        return;
                    }

                    const data = await response.json();
                    data.items.filter( playlist =>
                        playlist.owner.id === this.userId
                    ).forEach( playlist => {
                        playlists[playlist.id] = playlist
                    })
                    url = data.next; // For pagination
                }
                console.log("Fetched " + Object.keys(playlists).length + " playlists ")
                this.playlists = playlists;
            } catch (error) {
                console.error('Error fetching playlists:', error);
            }
        },
        async scanPlaylist(playlistId) {
            this.$sendEvent('playlists-scan')
            await this.fetchPlaylistTracks(playlistId);
            const unplayableCount = await this.countNonPlayableTracks(playlistId);
            const playlist = this.playlists[playlistId];
            // print the url
            if (playlist) {
                playlist.unplayableCount = unplayableCount;
            }
        },
        async fetchPlaylistTracks(playlistId) {
            this.currentPlaylistId = null
            this.currentPlaylistTracks = null
            let nonPlayableCount = 0;
            const accessToken = localStorage.getItem('spotify_access_token');
            let market = localStorage.getItem('market');
            let url = `https://api.spotify.com/v1/playlists/${playlistId}/tracks?market=${market}`;
            console.log("Scanning playlist " + playlistId + " in " + market)

            const tracks = [];
            let pos = 0
            try {
                while (url) {
                    const response = await fetch(url, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });

                    if (response.status === 401) {
                        console.log('Token expired');
                        this.$router.push({ name: 'login' });
                        return;
                    }

                    const data = await response.json();
                    for (const item of data.items) {
                        if (item.track.is_local) {
                            item.track.id = item.track.uri
                        }
                        if (!item.track.is_playable) {
                            nonPlayableCount++;
                        }
                        item.position = pos++;
                    }
                    tracks.push(...data.items); 
                    url = data.next; 
                }
                this.currentPlaylistTracks = tracks.sort((a, b) => {
                    if (a.track.is_playable && !b.track.is_playable) {
                        return 1;
                    } else if (!a.track.is_playable && b.track.is_playable) {
                        return -1;
                    } else {
                        if (a.track.is_local) {
                            return 1;
                        } else {
                            return -1;
                        }
                    }
                });
                this.currentPlaylistId = playlistId; 
                this.playlists[playlistId].unplayableCount = nonPlayableCount;
            } catch (error) {
                console.error('Error fetching playlist tracks:', error);
            }
        },
        async findAlternatives(trackId) {

            this.$sendEvent('tracks-find-alternatives')
            console.log("Finding alternatives for " + trackId)

            const [trackIndex, track] = this.findTrackInCurrentPlaylist(trackId);
            if (trackIndex === -1) return;

            if (this.currentPlaylistTracks[trackIndex].isScanned) {
                this.currentPlaylistTracks[trackIndex].showAlternatives = !this.currentPlaylistTracks[trackIndex].showAlternatives;
                console.log("Alternatives for " + trackId + "already found")
                return;
            }

            try {

                // Do an initial search with a stricter search criteria for higher quality results
                let data = await this.doSearch(this.cleanTitleStrict(track.track.name), track.track.artists[0].name);
                let selected = data.tracks.items
                    .map(candidate => this.scoreCandidate(candidate, track))
                    .filter(candidate => candidate.score > 0);

                if (selected.length <= 0) {
                    console.log("Failed to get high quality results, trying a more leninent search...")
                    data = await this.doSearch(this.cleanTitleLenient(track.track.name), track.track.artists[0].name);
                    selected = data.tracks.items
                        .map(candidate => this.scoreCandidate(candidate, track))
                        .filter(candidate => candidate.score > 0);
                }

                if (selected.length > 0) {
                    this.currentPlaylistTracks[trackIndex].alternatives = this.sortByScore(data.tracks.items);
                    this.currentPlaylistTracks[trackIndex].showAlternatives = true
                } else {
                    console.log("No alternatives found for " + track.track.name + " by " + track.track.artists[0].name)
                }

                this.currentPlaylistTracks[trackIndex].isScanned = true
            } catch (error) {
                console.error("Failed to fetch alternatives", error);
            }
        },
        async doSearch(title, artist) {
            console.log("Querying for alternatives for " + title + " by " + artist)
            // Avoid some common words that may affect the search
            const query = encodeURIComponent(
                `track:${title} artist:${artist}`
            )
            .replace(/'/g, ""); 

            // TODO: review this, seems to work better
            const market = this.selectedMarket;
            const url = `https://api.spotify.com/v1/search?q=${query}&type=track&market=${market}`;
            const accessToken = localStorage.getItem('spotify_access_token');
            const response = await fetch(url, {
                headers: { Authorization: `Bearer ${accessToken}` },
            });

            if (!response.ok) {
                console.error('Failed to search for alternative tracks:', response.body);
                return;
            }
            return response.json();
        },
        cleanTitleStrict(title) {
            return title.toLowerCase()
                .replace("original edit", "") 
                .replace("alternate version", "") 
                .replace("original version", "") 
                .replace(";", " ") 
                .replace("-", " ") 
                .replace(",", " ") 
                // remove multiple blank characters
                .replace(/\s\s+/g, ' ')
        },
        // This is helpful if the original track is one of those "xxxx remaster 2001 edit"
        // that pollutes the title so much that it won't find the normal track
        cleanTitleLenient(title) {
            return this.cleanTitleStrict(title)
                // replace anything that looks like a year
                .replace(/\b\d{4}\b/g, "")
                .replace("demo version", "") 
                .replace("remaster", "") 
        },
        /* Initial scoring function, tries to find higher quality matches */
        scoreCandidate(candidate, track) {
            candidate.score = 0;
            if (!candidate.is_playable) {
                candidate.score = 0;
                return candidate
            }
            if (candidate.artists[0].name == track.track.artists[0].name) {
                candidate.score += 1;
            }
            if (candidate.name == track.track.name) {
                candidate.score += 4;
            }
            // if the title matches exactly at the beginning, it is a good sign
            if (candidate.name.toLowerCase().startsWith(track.track.name.toLowerCase())) {
                candidate.score += 1;
            }
            // penalize remixes
            if (candidate.name.toLowerCase().includes("remix") && !track.track.name.toLowerCase().includes("remix")) {
                candidate.score -= 2;
            }
            if (track.track.name.toLowerCase().includes("live")) {
                // if the original track is live, prefer live alternatives
                if (candidate.name.toLowerCase().includes("live")) {
                    candidate.score += 2;
                }
            } else {
                // if the original track is live, prefer live alternatives
                if (candidate.name.toLowerCase().includes("live")) {
                    candidate.score -= 1;
                }
            }
            return candidate
        },
        sortByScore(candidates) {
            return candidates
                .filter(candidate => candidate.score > 0)
                .sort((a, b) => {
                    if (a.score === b.score) {
                        return 0;
                    }
                    return a.score > b.score ? -1 : 1;
                });
        },
        // Utility method because trackId is a uri in local files, which don't have an id
        findTrackInCurrentPlaylist(trackId) {
            let pos = 0;
            for (const track of this.currentPlaylistTracks) {
                if (track.track.id === trackId || (track.track.is_local && track.track.uri === trackId)) {
                    return [pos, track];
                }
                pos++;
            }
            return null; 
        },
        async applyFix(playlistId, trackId, alternativeId) {
            this.$sendEvent('tracks-fix')
            if (!playlistId) {
                console.log("Can't fix, no playlist selected")
                return 
            }
            if (!trackId) {
                console.log("Can't fix, no original track selected")
                return 
            }
            if (!alternativeId) {
                console.log("Can't fix, no alternative track selected")
                return 
            }
            const accessToken = localStorage.getItem('spotify_access_token');
            const [, track] = this.findTrackInCurrentPlaylist(trackId);
            if (!track) {
                console.log("Track not found in playlist!")
                return;
            }

            console.log("Applying fix to " + playlistId + " replacing " + trackId + " with " + alternativeId + " in position " + track.position)

            // Start by adding the alternative
            const url = `https://api.spotify.com/v1/playlists/${playlistId}/tracks?uris=spotify:track:${alternativeId}&position=${track.position}`;
            const postResponse = await fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!postResponse.ok) {
                console.error('Failed to add alternative track:', postResponse.body);
                return;
            }

            console.log('Alternative track was successfully added ' + alternativeId);
            
            // If all went well, delete the old track
            if (track.track.is_local) {
                console.log("Local track, Spotify won't allow to remove it")
            } else {
                const deleteUrl = `https://api.spotify.com/v1/playlists/${playlistId}/tracks`;
                const deleteResponse = await fetch(deleteUrl, {
                    method: 'DELETE',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        tracks: [{ uri: `spotify:track:${trackId}` }],
                    }),
                });

                console.log('The old unplayable track was removed ' + alternativeId);

                if (!deleteResponse.ok) {
                    console.error('Failed to remove original track:', deleteResponse.body);
                    return;
                }
            }

            this.playlists[playlistId].unplayableCount--;
            track.isFixed = true;
            track.alternatives = track.alternatives.filter(alternative => alternative.id == alternativeId);
            if (track.alternatives.length == 1) {
                track.alternatives[0].chosen = true;
            } else {
                console.log("Error, more than one alternatives left after a replacement!")
            }
        },
        async fetchTrack(trackId) {
            const accessToken = localStorage.getItem('spotify_access_token');
            const url = `https://api.spotify.com/v1/tracks/${trackId}`;
            try {
                const response = await fetch(url, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                });
                return await response.json();
            } catch (error) {
                console.error('Failed to fetch track:', error);
            }
        },
    },
};
</script>


<style scoped>
.splitcontent {
    display: flex;
    gap: 10px;
    height: 100%;
    max-height: 100%;
}

.playlistsContent, .details {
    overflow-y: scroll;
    h2 {
        margin-top: 0;
    }
}

.playlistsContent {
    height: 100%;
}

.marketSelector {
    margin: 20px 0 20px 0;
    display: flex;
    span {
        flex: 1;
    }
}

.playlists {
    flex: 1;
    overflow: hidden;
    max-height: 60vh;
}

.details {
    flex: 2;
    padding-left: 40px;
    max-height: 60vh;
}

.details ul, .playlists ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.details li, .playlists li {
    margin: 5px 0;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: color 0.2s, background-color 0.2s;
    transition: background-color 0.2s, background-color 0.2s;
}

.details li:hover, .playlists li:hover {
    background-color: #242424;
    border-radius: 5px;
}

.playlists ul {
    padding-right: 10px;
}

ul.alternatives {
    margin: 10px 0 0 25px;
    padding: 10px 0 0 10px;
    border-left: 1px solid #666;
}

ul.alternatives li {
    padding-left: 10px;
    margin-bottom: 10px;
}

ul.alternatives li:hover {
    background-color: #333;
}

div.track {
    width: 100%;
    margin: 5px 0;
}

.splitcontent button {
    flex-shrink: 0;
    padding: 0.2rem;
    font-size: 0.8rem;
    cursor: pointer;
    gap:20px;
}

.non_playable button {
    background-color: transparent;
    color: #ccc;
    transition: color 0.2s;
}

.non_playable button:hover {
    color: #1Db954;
}

button.disabled {
    background-color: transparent;
    color: #888;
    cursor: auto;
}

button.repaired {
    color: #1Db954;
}

div.rowish {
    flex-grow: 1;
    display: flex;
}

div.rowish div {
    flex-grow: 1;
    display: flex;
}

.trackMeta {
    margin-left: 10px;
    flex-direction:column;
    .artist {
        font-size: 0.8rem;
    }
}

.detailsHead, .playlistsHead {
    min-height: 150px;
    margin: 20px 0;
}

.playlistsHead {
    h2 {
        margin-top: 0px;
    }
    margin-right: 30px;
}

.detailsHead {
    display: flex;
    h2 {
        font-size: 3rem;
        display: inline;
        margin: 0px;
    }
    div.cover img {
        width: 140px;
        height: 140px;
        margin-right: 20px;
    }

    div.info {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
}
.hint {
    margin-left: 10px;
    cursor: help;
    font-size: 0.8rem;
    border: 1px solid #888;
    color: #1Db954;
    font-weight: bolder;
    padding: 5px;
    display: inline-block;
    text-align: center;
    border-radius: 16px;
}
.hint:hover {
    background-color: #1Db954;
    color: #333;
}

.coversmall {
    background-color: #333;
    width: 40px;
    height: 40px;
}
span.coversmall {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
}

img.spotifylogo {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.icon {
    cursor: help;
    margin-right: 5px;
    font-size: 20px;
    width: 25px;
}

a.broken_link {
    color: #888;
}

a.broken_link:hover {
    color: #1Db954;
}

.non_playable::before {
    content: " ";
}

.fixed {
    color: gray;
}
.chosen {
    color: #1Db954;
}
.local {
    color: #444;
}
.cross {
    margin-right: 5px;
    font-weight: bolder;
    color: #1Db954;
}
select {
    background: #181818;
    border: 1px solid #333;
    color: #ccc;
    padding: 0 5px;
}
.playlists::-webkit-scrollbar, .details::-webkit-scrollbar {
    width: 5px;
}

.playlists::-webkit-scrollbar-thumb, .details::-webkit-scrollbar-thumb {
    background: #888;
}

.playlists::-webkit-scrollbar-thumb:hover, .details::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@media (max-width: 1024px) {
.playlistsContent, .details {
    h2 {
        font-size: 1.2em;
    }
}
}
</style>
