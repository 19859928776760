<template>
  <div class="header">
    <div>
      <h1>Playlist <span>+</span> Hospital</h1>
      <h2>Repair unplayable tracks in your Spotify playlists </h2>
    </div>
  </div>
  <div class="content">
    <router-view />
  </div>
  <div class="footer">
    <div>
      <p>
        Made by <a href="https://github.com/srvaroa">@srvaroa</a> with data provided by <a href="Spotify" alt="Spotify">Spotify</a>. 
      </p>
      <p>
        &copy; Playlist Hospital {{ new Date().getFullYear() }}<br />
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  mounted() {
    document.title = 'Playlist Hospital: repair unplayable tracks in your Spotify playlists';
  },
  components: {}
}
</script>

<style>

body {
  max-height: 100vh;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background-color: #121212;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6;
  font-family:
    Inter,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
}

#app {
  padding: 0 20px;
  color: #888;
  background-color: #181818;
  display: flex;
  flex-direction:column;
  overflow: hidden;
  max-width: 1280px;
  margin: 0 auto;
  height: 100vh;
}

.header, .footer {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header {
    padding: 20px 0 50px 0;
    margin-bottom: 50px;
    border-bottom: 1px solid #333;
    text-align: center;
    h2 {
      font-size: 1.4rem;
      font-weight: normal;
      margin: 10px 0 0 0;
    }
}

.footer {
  text-align: center;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #333;
  ul {
    display: flex;
    justify-content: center;
    list-style-type: none;
    li {
      padding: 10px;
    }
  }
}

.content {
  flex-grow: 1;
}

button {
  /* background-color: hsla(160, 100%, 37%, 1); */
  background-color: #1Db954;
  border: none;
  border-radius: 4px;
  color: black;
  margin-right: 10px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
}

a {
    text-decoration: none;
    color: #ccc;
    transition: color 0.2s;
}

.green {
    color: #1Db954;
    /* color: hsla(160, 100%, 47%, 1); */
}

@media (hover: hover) {
  a:hover {
    color: #1Db954;
    /* color: hsla(160, 100%, 47%, 1); */
  }
}

h1 {
    color: #1Db954;
    /* color: hsla(160, 100%, 27%, 1);*/
    margin: 0;
    font-size: 4em;
    span {
      font-size: 150%;
      color: #972121;
    }
}

/* reduce font size if screen is smaller */
@media (max-width: 1024px) {
  body {
    font-size: 12px;
  }
  h1 {
    font-size: 2em;
  }
  h2, h3 {
      font-size: 1.2em;
  }
  .header h2 {
    font-size: 1.2em;
  }
}

</style>
