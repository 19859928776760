<template>
    <div>
        Redirecting...
    </div>
</template>

<script>
export default {
    name: 'TheCallback',
    mounted() {
        const hash = window.location.hash.substring(1);
        const params = new URLSearchParams(hash);
        const accessToken = params.get('access_token');

        if (accessToken) {
            console.log("We have token " + accessToken)
            localStorage.setItem('spotify_access_token', accessToken);
            this.$router.push('/playlists');
        } else {
            console.error('Token was not found in URL hash');
            this.$router.push('/login');
        }
    },
};
</script>