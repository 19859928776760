import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { sendEvent } from './telemetry'

const app = createApp(App)

app.config.globalProperties.$rootUri = process.env.NODE_ENV === 'production'
    ? 'https://playlisthospital.com'
    : 'http://localhost:8080';

app.config.globalProperties.$sendEvent = sendEvent;

app.use(router).mount('#app')
